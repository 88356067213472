<template>
  <button
    style="width: 100px; height: 50px; opacity: 0"
    @click="toClearData"
    class="btn-login"
  >
    clear
  </button>
  <div class="my has-nav">
    <div class="info">
      <img
        style="width: 90px; height: 90px"
        v-if="userInfo"
        :src="userInfo.avatar"
        class="headshot"
        mode="aspectFit"
      />
      <text v-if="userInfo">{{ userInfo.nickname }}</text>
      <t-button
        size="large"
        theme="primary"
        shape="round"
        class="btn-login"
        v-if="!userInfo"
        @click="getLoginCode"
      >
        点击登录
      </t-button>
    </div>
    <div class="card">
      <div class="panel-top" @click="$router.push('/favlist')">
        <div class="left">
          <div class="icon-circle">
            <icon name="thumb-up-2" />
          </div>
          <!-- <img src="@/assets/images/icon-zan.svg" class="icon-zan" /> -->
          我的点赞记录
        </div>
        <icon name="chevron-right-s" size="24px" style="color: #f7842c" />
        <!-- <img src="@/assets/images/arrow.svg" class="arrow" /> -->
      </div>
      <div class="panel-body" v-if="userInfo">
        <div class="item">
          {{ userInfo.today_vote_numbers }}
          <text>今日已点赞</text>
        </div>
        <div class="item">
          {{ userInfo.remain_vote_numbers }}
          <text>今日剩余点赞</text>
        </div>
        <div class="item">
          {{ userInfo.vote_numbers }}
          <text>总点赞</text>
        </div>
      </div>
    </div>
    <div class="card tt">
      <div class="panel-top" @click="testUp">
        <div class="left">
          <img src="@/assets/images/icon-zan.svg" class="icon-zan" />
          test upload
        </div>
        <icon name="chevron-right-s" />
        <!-- <img src="@/assets/images/arrow.svg" class="arrow" /> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "tdesign-icons-vue-next";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { userDetail, wechatLogin } from "../network/API";
import { useUserStore } from "../store/user";
import tools from "../utils/tools";
const userStore = useUserStore();
const userInfo = ref(null);
const router = useRouter();

onMounted(() => {
  if (userStore.token && userStore.token.length > 0) {
    userInfo.value = userStore.userInfo;
    getUserDetail();
    router.replace("/my");
  } else {
    const curPath = window.location.href;
    const code = parseURL(curPath);
    if (code != null && code.length > 0) {
      toLogin(code);
      return;
    }
  }
  tools.shareInit();
});
function toClearData() {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
}
async function getUserDetail() {
  const data = await userDetail();
  userInfo.value = data;
  userStore.saveUser(data);
}
function toFavList() {}
async function getLoginCode() {
  // const tk = "w04gcoggkowkkosccsgswsgsg0ccosw0sc0k8wwo";
  // await userStore.saveToken(tk);
  // getUserDetail();
  // return;
  window.location.href =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7da5e703d24fa74f&redirect_uri=https://yztzy.smartzhengwu.cn/my&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
}
function parseURL(url) {
  var url = new URL(url);
  var searchParams = new URLSearchParams(url.search);
  var code = searchParams.get("code");
  return code;
}
async function toLogin(code) {
  try {
    var res = await wechatLogin({
      code: code,
    });
    if (res.token) {
      userStore.saveToken(res.token);
    }
    getUserDetail();
  } catch (error) {
    // alert("5===" + error);
  }
}
</script>

<style scoped>
.card {
  position: relative;
  z-index: 3;
  margin: 15px;
  padding: 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.info {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: #fff;
}

.info text {
  font-size: 18px;
  font-weight: 700;
}

.headshot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-bottom: 20rpx;
  border: 2rpx solid #fff;
}

.btn-login {
  background: #fff;
  border-radius: 50rpx;
  color: #f7842c;
}

.icon-zan {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.arrow {
  width: 7px;
  height: 13px;
  margin-right: 10px;
}

.panel-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}

.panel-top .left {
  display: flex;
  align-items: center;
  color: #333;
}

.panel-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.panel-body .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #333333;
}

.panel-body .item text {
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  margin-top: 5px;
}

.tt {
  opacity: 0;
}
.icon-circle {
  background: #f7842c;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
}
</style>
